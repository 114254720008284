<template>
    <div class="autoupdater">
        <v-dialog
            v-model="update_available_dialog"
            max-width="480"
            persistent
        >
            <v-card>
                <v-card-title> Dostępna aktualizacja </v-card-title>

                <v-card-text>
                    Korzystasz ze starej wersji aplikacji. Kliknij poniżej, aby pobrać najnowszą
                    wersję zawierającą nowe funkcjonalności i&nbsp;poprawki błędów.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey"
                        text
                        @click="update_available_dialog = false"
                    >
                        Później
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="updateApp"
                    >
                        Aktualizuj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="update_dialog"
            max-width="480"
            persistent
        >
            <v-card>
                <v-card-title> Aktualizowanie aplikacji </v-card-title>

                <v-card-text>
                    <template v-if="!update_completed">
                        Trwa pobieranie nowej wersji aplikacji...
                    </template>
                    <div v-if="update_completed">
                        <div class="text-center mb-2">Proszę czekać...</div>
                        <div class="d-flex justify-center mb-4">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </div>
                    </div>
                    <div
                        class="d-flex align-center mb-4"
                        v-else
                    >
                        <v-progress-linear
                            color="primary"
                            :value="update_dialog_progress"
                            :buffer-value="0"
                            stream
                            rounded
                        ></v-progress-linear>
                        <span
                            style="width: 48px"
                            class="text-right primary--text"
                        >
                            {{ update_dialog_progress }}%
                        </span>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { lt, valid as isValidSemverVersion } from "semver";
import waitForMs, { Stopwatch } from "../helpers/waiters";
import { appIsFocused } from "../helpers/generics";
import { EventBus } from "./EventBus";

const LOCAL_STORAGE_LCHECK_KEY = "AutoUpdater::lastUpdateCheckDate";
let remote_document = null;
let update_start_time = new Stopwatch();

export default {
    data: () => ({
        performing_update: false,
        update_completed: false,

        update_available_dialog: false,

        update_dialog: false,
        update_dialog_progress: 0,
        update_dialog_assets_count: 0,
        update_dialog_assets_count_loaded: 0,

        events_mounted: false
    }),

    methods: {
        /*###################
        ### INNER HELPERS ###
        ###################*/
        async fetchHTML(path) {
            const R = await fetch(path);
            const HTML = await R.text();
            const PARSER = new DOMParser();
            return PARSER.parseFromString(HTML, "text/html");
        },
        async getAppVersionFromHTML(doc) {
            const RV_TAG = doc.querySelector('meta[name="app-version"]');
            let v = "0.0.0";
            if (RV_TAG) {
                const TV = RV_TAG.getAttribute("content");
                if (TV && isValidSemverVersion(TV)) {
                    v = TV;
                }
            }
            return v;
        },

        /*#######################
        ### PERFORMING UPDATE ###
        #######################*/
        getAssets() {
            if (remote_document) return [];

            const ASSETS = [...remote_document.querySelectorAll("link[href], script[src]")];

            return ASSETS.filter(el => {
                const ATTR = el.getAttribute(el.tagName === "LINK" ? "href" : "src");
                return (
                    ATTR.startsWith("/static") && (ATTR.endsWith(".css") || ATTR.endsWith(".js"))
                );
            })
                .map(el => {
                    return {
                        path: el.getAttribute(el.tagName === "LINK" ? "href" : "src"),
                        tag: el.tagName === "LINK" ? "link" : "script",
                        is_module_script:
                            el.tagName !== "LINK" && el.getAttribute("type") === "module"
                    };
                })
                .filter((a, index, self) => index === self.findIndex(b => b.path === a.path));
        },

        async handleUpdateProgress() {
            if (this.update_dialog_assets_count_loaded === this.update_dialog_assets_count) {
                // Finished
                await waitForMs(600);
                await update_start_time.waitUntil(1300);
                this.update_dialog_progress = 100;
                await waitForMs(800);
                this.update_completed = true;
                window.location.reload();
            } else {
                // In Progress
                this.update_dialog_progress += Math.floor(80 / update_dialog_assets_count);
            }
        },
        reportAssetUpdateProgress() {
            this.update_dialog_assets_count_loaded += 1;
            this.handleUpdateProgress();
        },

        async updateApp() {
            if (this.performing_update) return;

            this.performing_update = true;
            update_start_time = new Stopwatch();

            this.update_available_dialog = false;
            this.update_dialog = true;
            this.update_dialog_progress = 0;

            // 1. Na początku ustawiamy na 20%
            await waitForMs(250);
            this.update_dialog_progress = 20;

            // 2. Zbieramy wszystkie assety *.js oraz *.css
            const ASSETS = this.getAssets();
            this.update_dialog_assets_count = ASSETS.length;

            // 3. Pobieramy assety
            ASSETS.forEach(a => {
                let el;

                if (a.tag === "link") {
                    el = document.createElement("link");
                    el.rel = "stylesheet";
                    if (a.path) el.href = a.path;
                } else if (a.tag === "script") {
                    el = document.createElement("script");
                    if (a.is_module_script) {
                        el.setAttribute("type", "module");
                    }
                    if (a.path) el.src = a.path;
                }

                if (!el) return;

                document.head.appendChild(el);

                el.onload = () => {
                    document.head.removeChild(el);
                    this.reportAssetUpdateProgress();
                };

                el.onerror = () => {
                    document.head.removeChild(el);
                    this.reportAssetUpdateProgress();
                };
            });
            if (ASSETS.length === 0) {
                this.handleUpdateProgress();
            }
        },

        /*#####################################
        ### CHECKING IF UPDATE IS AVAILABLE ###
        #####################################*/
        async checkForUpdates() {
            if (this.performing_update) return;

            // 1. Odstęp min. 30min pomiędzy sprawdzeniami
            const LUCD = window.localStorage.getItem(LOCAL_STORAGE_LCHECK_KEY);
            if (LUCD && /^[1-9][0-9]{0,24}$/.test(LUCD)) {
                const PLUCD = parseInt(LUCD);
                if (!isNaN(PLUCD) && Date.now() - PLUCD < 30 * 60 * 1000) return;
            }

            // 2. Sprawdzenie wersji
            remote_document = await this.fetchHTML("/?v=" + Date.now());
            const REMOTE_VERSION = await this.getAppVersionFromHTML(remote_document);
            if (lt(process.env.VUE_APP_NPM_PACKAGE_VERSION, REMOTE_VERSION)) {
                this.update_available_dialog = true;
            }

            // 3. Raportowanie czasu sprawdzenia
            window.localStorage.setItem(LOCAL_STORAGE_LCHECK_KEY, Date.now().toString());
        },

        /*############################
        ### BROWSER REFOCUS EVENTS ###
        ############################*/
        onBrowserActiveStateChange(is_active = false) {
            if (is_active === true) {
                this.checkForUpdates();
            }
        }
    },

    async mounted() {
        await waitForMs(3000);
        if (await appIsFocused()) {
            this.checkForUpdates();
        }

        EventBus.on("Browser::activestatechange", this.onBrowserActiveStateChange);
        this.events_mounted = true;
    },
    beforeDestroy() {
        EventBus.off("Browser::activestatechange", this.onBrowserActiveStateChange);
    }
};
</script>
