var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cbe-block",class:{
        'cbe-block--drag-disabled': _vm.dragDisabled
    }},[_c('div',{staticClass:"cbe-block__content",class:{
            'cbe-block__content--limited': _vm.block.block_type !== _vm.ContentBlockType.ROW_2COL
        }},[(_vm.block.block_type == _vm.ContentBlockType.IMAGE)?_c('div',{staticClass:"cbe-block__imgcontent"},[_c('figure',{staticClass:"cbe-block__image"},[_c('v-img',{attrs:{"lazy-src":_vm.image_lazy_url,"src":_vm.image_url}}),(_vm.block.image.caption != '')?_c('figcaption',[_vm._v(" "+_vm._s(_vm.block.image.caption)+" ")]):_vm._e()],1)]):(_vm.block.block_type == _vm.ContentBlockType.WYSIWYG)?_c('div',{staticClass:"cbe-block__wysiwygcontent",domProps:{"innerHTML":_vm._s(_vm.block.wysiwyg.content)}}):(_vm.block.block_type == _vm.ContentBlockType.ANIMATED_SUMMARY_LIST)?_c('div',{staticClass:"cbe-block__aslcontent"},[_c('ul',_vm._l((_vm.block.animated_summary_list.items),function(item,ix){return _c('li',{key:ix},[_c('v-icon',{staticClass:"mr-2 flex-shrink-0",attrs:{"size":"20","color":"primary"}},[_vm._v(" "+_vm._s(_vm.ContentBlockAnimatedSummaryListItemIconName[item.icon])+" ")]),_c('div',{staticClass:"align-self-center flex-grow-1"},_vm._l((_vm.getPossibleLogicExpressionOutputs(item.text)),function(e,ix2){return _c('div',{key:ix2,class:{
                                'mt-n1': ix2 > 0
                            }},[(ix2 > 0)?_c('span',{staticClass:"grey--text"},[_vm._v(" lub ")]):_vm._e(),_vm._v(" "+_vm._s(e)+" ")])}),0)],1)}),0)]):(_vm.block.block_type == _vm.ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT)?_c('div',{staticClass:"cbe-block__quizboardpredc"},[_vm._v(" Tu wyświetlona zostanie zawartość Planszy "),_c('br'),_vm._v(" (np. zdefiniowane odpowiedzi na zadane pytanie). ")]):(_vm.block.block_type == _vm.ContentBlockType.CHART)?_c('div',{staticClass:"cbe-block__chart grey lighten-4",style:(`height: ${_vm.block.chart.height}px !important`)},[_c('v-icon',{attrs:{"color":"grey lighten-1","size":"32"}},[_vm._v("mdi-chart-line")])],1):(_vm.block.block_type == _vm.ContentBlockType.QUIZ_NEXT_BOARD_BUTTON_PLACEHOLDER)?_c('div',{staticClass:"cbe-block__nextbtnplaceholder"},[_vm._v(" W tym miejscu zostanie wyświetlony przycisk przejścia do następnej planszy. ")]):(_vm.block.block_type == _vm.ContentBlockType.VIMEO_VIDEO)?_c('div',{staticClass:"cbe-block__vimeovideo"},[_c('iframe',{attrs:{"src":_vm.getVimeoVideoUrl(_vm.block.vimeo_video),"width":"100%","height":"100%","frameborder":"0","webkitallowfullscreen":"","mozallowfullscreen":"","allowfullscreen":""}})]):(_vm.block.block_type == _vm.ContentBlockType.ROW_2COL)?_c('div',{staticClass:"cbe-block-row2col"},[_c('div',{staticClass:"cbe-block-row2col__header text-caption text-uppercase grey--text text-center"},[_vm._v(" "+_vm._s(_vm.ContentBlockTypeLabel[_vm.block.block_type])+" ("+_vm._s(_vm.block.row_2col.layout)+") ")]),_c('div',{staticClass:"cbe-block-row2col__grid"},[_c('div',{staticClass:"cbe-block-row2col__col",class:{
                        'cbe-block-row2col__col--40':
                            _vm.block.row_2col.layout === _vm.ContentBlockRow2ColLayout._40_60,
                        'cbe-block-row2col__col--50':
                            _vm.block.row_2col.layout === _vm.ContentBlockRow2ColLayout._50_50,
                        'cbe-block-row2col__col--60':
                            _vm.block.row_2col.layout === _vm.ContentBlockRow2ColLayout._60_40
                    },on:{"click":function($event){$event.stopPropagation();},"dblclick":function($event){$event.stopPropagation();},"contextmenu":function($event){$event.stopPropagation();}}},[_c('ContentBlockEditor',{attrs:{"quiz-context":"","quiz-boards":_vm.quizBoards,"blocks-filter":_vm.blocksFilter,"disallowed-block-types":[_vm.ContentBlockType.ROW_2COL]},on:{"update":function($event){return _vm.$emit('update')}},model:{value:(_vm.block.row_2col.blocks_col1),callback:function ($$v) {_vm.$set(_vm.block.row_2col, "blocks_col1", $$v)},expression:"block.row_2col.blocks_col1"}})],1),_c('div',{staticClass:"cbe-block-row2col__col",class:{
                        'cbe-block-row2col__col--40':
                            _vm.block.row_2col.layout === _vm.ContentBlockRow2ColLayout._60_40,
                        'cbe-block-row2col__col--50':
                            _vm.block.row_2col.layout === _vm.ContentBlockRow2ColLayout._50_50,
                        'cbe-block-row2col__col--60':
                            _vm.block.row_2col.layout === _vm.ContentBlockRow2ColLayout._40_60
                    },on:{"click":function($event){$event.stopPropagation();},"dblclick":function($event){$event.stopPropagation();},"contextmenu":function($event){$event.stopPropagation();}}},[_c('ContentBlockEditor',{attrs:{"quiz-context":"","quiz-boards":_vm.quizBoards,"blocks-filter":_vm.blocksFilter,"disallowed-block-types":[_vm.ContentBlockType.ROW_2COL]},on:{"update":function($event){return _vm.$emit('update')}},model:{value:(_vm.block.row_2col.blocks_col2),callback:function ($$v) {_vm.$set(_vm.block.row_2col, "blocks_col2", $$v)},expression:"block.row_2col.blocks_col2"}})],1)])]):_vm._e()]),_c('div',{staticClass:"cbe-block__dragoverlay"},[_c('v-btn',{staticClass:"cbe-block__draghandle",attrs:{"icon":"","color":"secondary","title":"Przesuń blok"}},[_c('v-icon',[_vm._v("mdi-dots-grid")])],1)],1),(_vm.showVisibilityIcons)?_c('div',{staticClass:"cbe-block__visibilityicons",attrs:{"title":"Widoczność bloku"}},[(
                (_vm.block.visible_sm === true || _vm.block.visible_sm === undefined) &&
                (_vm.block.visible_md === true || _vm.block.visible_md === undefined) &&
                (_vm.block.visible_lg === true || _vm.block.visible_lg === undefined)
            )?_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-all-inclusive")]):[(_vm.block.visible_sm === true || _vm.block.visible_sm === undefined)?_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-cellphone")]):_vm._e(),(_vm.block.visible_md === true || _vm.block.visible_md === undefined)?_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-tablet")]):_vm._e(),(_vm.block.visible_lg === true || _vm.block.visible_lg === undefined)?_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-monitor")]):_vm._e()]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }