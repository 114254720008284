import { Weekday } from "./General.js";

export const SelfdevProgramType = Object.freeze({
    FIXED: "fixed",
    WEEKLY_SEMIFLEX_2EP: "weekly_semiflex_2ep",
    WEEKLY_SEMIFLEX_1EP: "weekly_semiflex_1ep"
});

export const SelfdevProgramTypeLabel = Object.freeze({
    [SelfdevProgramType.FIXED]: "Sztywny harmonogram",
    [SelfdevProgramType.WEEKLY_SEMIFLEX_2EP]: "Dwa epizody w tyg.",
    [SelfdevProgramType.WEEKLY_SEMIFLEX_1EP]: "Jeden epizod w tyg."
});

export const SelfdevProgramTypeSelectItems = Object.values(SelfdevProgramType).map(it => {
    return {
        text: SelfdevProgramTypeLabel[it],
        value: it
    };
});

export const SelfdevProgramStatus = Object.freeze({
    DRAFT: "draft",
    PUBLISHED: "published",
    DELETED: "deleted"
});

export const SelfdevProgramStatusLabel = Object.freeze({
    [SelfdevProgramStatus.DRAFT]: "Szkic",
    [SelfdevProgramStatus.PUBLISHED]: "Opublikowany",
    [SelfdevProgramStatus.DELETED]: "Usunięty"
});

export const SelfdevProgramStatusColor = Object.freeze({
    [SelfdevProgramStatus.DRAFT]: "secondary",
    [SelfdevProgramStatus.PUBLISHED]: "success",
    [SelfdevProgramStatus.DELETED]: "error"
});

export const SelfdevProgramStatusSelectItems = Object.values(SelfdevProgramStatus).map(it => {
    return {
        text: SelfdevProgramStatusLabel[it],
        value: it
    };
});

export const SelfdevProgramWeekday = Object.freeze({
    ...Weekday
});

export const SelfdevProgramWeekdayLabel = Object.freeze({
    [SelfdevProgramWeekday.MONDAY]: "PN",
    [SelfdevProgramWeekday.TUESDAY]: "WT",
    [SelfdevProgramWeekday.WEDNESDAY]: "ŚR",
    [SelfdevProgramWeekday.THURSDAY]: "CZ",
    [SelfdevProgramWeekday.FRIDAY]: "PT",
    [SelfdevProgramWeekday.SATURDAY]: "SB",
    [SelfdevProgramWeekday.SUNDAY]: "ND"
});
export const SelfdevProgramWeekdayLabelFull = Object.freeze({
    [SelfdevProgramWeekday.MONDAY]: "Poniedziałek",
    [SelfdevProgramWeekday.TUESDAY]: "Wtorek",
    [SelfdevProgramWeekday.WEDNESDAY]: "Środa",
    [SelfdevProgramWeekday.THURSDAY]: "Czwartek",
    [SelfdevProgramWeekday.FRIDAY]: "Piątek",
    [SelfdevProgramWeekday.SATURDAY]: "Sobota",
    [SelfdevProgramWeekday.SUNDAY]: "Niedziela"
});
export const SelfdevProgramWeekdaySelectItems = Object.values(SelfdevProgramWeekday).map(it => {
    return {
        text: SelfdevProgramWeekdayLabelFull[it],
        value: it
    };
});

export const SelfdevProgramContractTemplateLayout = Object.freeze({
    DEFAULT: "default"
});

export const SelfdevProgramContractTemplateLayoutLabel = Object.freeze({
    [SelfdevProgramContractTemplateLayout.DEFAULT]: "Domyślny"
});

export const SelfdevProgramContractTemplateLayoutSelectItems = Object.values(
    SelfdevProgramContractTemplateLayout
).map(it => {
    return {
        text: SelfdevProgramContractTemplateLayoutLabel[it],
        value: it
    };
});

export const SelfdevProgramWorkbookTemplateLayout = Object.freeze({
    DEFAULT: "default"
});

export const SelfdevProgramWorkbookTemplateLayoutLabel = Object.freeze({
    [SelfdevProgramWorkbookTemplateLayout.DEFAULT]: "Domyślny"
});

export const SelfdevProgramWorkbookTemplateLayoutSelectItems = Object.values(
    SelfdevProgramWorkbookTemplateLayout
).map(it => {
    return {
        text: SelfdevProgramWorkbookTemplateLayoutLabel[it],
        value: it
    };
});

export const SelfdevProgramCertificateFont = Object.freeze({
    MONTSERRAT_SEMIBOLD: "montserrat_semibold",
    POPPINS_MEDIUM: "poppins_medium"
});

export const SelfdevProgramCertificateFontLabels = Object.freeze({
    [SelfdevProgramCertificateFont.MONTSERRAT_SEMIBOLD]: "Montserrat",
    [SelfdevProgramCertificateFont.POPPINS_MEDIUM]: "Poppins"
});

export const SelfdevProgramCertificateFontSelectItems = Object.values(
    SelfdevProgramCertificateFont
).map(it => {
    return {
        text: SelfdevProgramCertificateFontLabels[it],
        value: it
    };
});

export const SelfdevProgramTag = Object.freeze({
    STARTER_PROGRAM_V1: "starter_program_v1",
    KIND__BOOST_ENERGY: "kind__boost_energy",
    KIND__CONCENTRATE_ON_PRESENT: "kind__concentrate_on_present",
    KIND__STRENGTHEN_SELF_CONFIDENCE: "kind__strengthen_self_confidence",
    KIND__IMPROVE_SLEEP: "kind__improve_sleep",
    KIND__IMPROVE_PRODUCTIVITY: "kind__improve_productivity"
});
export const SelfdevProgramTagLabel = Object.freeze({
    [SelfdevProgramTag.STARTER_PROGRAM_V1]: "Program początkowy (MVP)",
    [SelfdevProgramTag.KIND__BOOST_ENERGY]: "Rodzaj: Zwiększenie energii",
    [SelfdevProgramTag.KIND__CONCENTRATE_ON_PRESENT]: "Rodzaj: Skupienie na teraźniejszości",
    [SelfdevProgramTag.KIND__STRENGTHEN_SELF_CONFIDENCE]: "Rodzaj: Wzmocnienie pewności siebie",
    [SelfdevProgramTag.KIND__IMPROVE_SLEEP]: "Rodzaj: Poprawa jakości snu",
    [SelfdevProgramTag.KIND__IMPROVE_PRODUCTIVITY]: "Rodzaj: Zwiększenie produktywności"
});
export const SelfdevProgramTagSelectItems = Object.values(SelfdevProgramTag).map(it => {
    return {
        text: SelfdevProgramTagLabel[it],
        value: it
    };
});
