var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('v-app-bar',{staticClass:"mb-1 rounded align-center",staticStyle:{"position":"sticky","top":"0","z-index":"1"},attrs:{"color":_vm.selected_category ? 'primary' : '',"light":_vm.selected_category === null,"outlined":!_vm.selected_category,"dark":_vm.selected_category !== null,"dense":"","flat":""}},[(!_vm.selected_category)?[(_vm.parent_category)?_c('v-btn',{staticClass:"ml-n1 mr-2",attrs:{"icon":"","small":"","title":'Wróć do ' +
                    (_vm.grand_parent_category ? _vm.grand_parent_category.name : _vm.ROOT_FOLDER_NAME)},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-arrow-left")])],1):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.breadcrumb_items.join(" / "))+" ")])]:[_c('v-btn',{staticClass:"ml-n1 mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.toggleCategorySelection(_vm.selected_category)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-close")])],1),_c('div',[_c('span',{staticStyle:{"opacity":"0.6"}},[_vm._v("Wybrano: ")]),_vm._v(_vm._s(_vm.selected_category.name))])],_c('v-spacer'),(!_vm.selected_category)?[(!_vm.readonly)?_c('v-btn',{attrs:{"icon":"","small":"","title":"Dodaj nową kategorię w tym miejscu"},on:{"click":_vm.openAddDialog}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-plus-circle")])],1):_vm._e()]:[(!_vm.readonly)?[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","title":"Edytuj wybraną kategorię"},on:{"click":_vm.openEditDialog}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","loading":_vm.delete_loading,"title":"Usuń wybraną kategorię"},on:{"click":function($event){_vm.delete_dialog = true}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"icon":"","small":"","title":"Przenieś wybraną kategorię"},on:{"click":() => {
                            if (_vm.selected_category.parent_category) {
                                _vm.move_to_category_id = _vm.selected_category.parent_category;
                            }
                            _vm.move_dialog = true;
                        }}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-folder-arrow-right")])],1),_c('div',{staticClass:"grey lighten-2 mx-4",staticStyle:{"width":"1px","height":"100%"}})]:_vm._e(),(_vm.selected_category)?_c('div',{staticClass:"pr-0"},[_c('v-btn',{attrs:{"icon":"","small":"","title":'Wejdź do kategorii ' + _vm.selected_category.name},on:{"click":function($event){return _vm.loadChildren(_vm.selected_category)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-folder-open")])],1)],1):_vm._e()]],2),(_vm.categories.length > 0)?[_vm._l((_vm.categories),function(cat,cix){return _c('v-list-item',{key:cat._id,staticStyle:{"cursor":"pointer","user-select":"none"},style:(cix !== _vm.categories.length - 1
                    ? {
                          'border-bottom': '1px solid #ededed'
                      }
                    : {}),attrs:{"input-value":_vm.selected_category && cat._id === _vm.selected_category._id,"color":"primary"},on:{"click":function($event){return _vm.onCategoryItemClick(cat)}}},[(_vm.selected_category && cat._id === _vm.selected_category._id)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":"primary"}},[_vm._v("mdi-radiobox-marked")]):_c('v-icon',{staticClass:"mr-2",attrs:{"size":"20","color":"grey"}},[_vm._v("mdi-folder")]),_vm._v(" "+_vm._s(cat.name)+" ")],1)}),(!_vm.categories_loading && _vm.has_more_current_category)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onCategoryIntersect),expression:"onCategoryIntersect"}],staticStyle:{"height":"1px"}}):_vm._e()]:(_vm.categories.length === 0 && !_vm.categories_loading)?_c('div',[_c('div',{staticClass:"text-center grey--text text-caption py-2"},[_vm._v(" Brak elementów do wyświetlenia ")])]):_vm._e(),(_vm.categories_loading)?_c('div',{staticClass:"text-center py-2"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"24","width":"2"}})],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","persistent":""},model:{value:(_vm.add_dialog),callback:function ($$v) {_vm.add_dialog=$$v},expression:"add_dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',[_vm._v(" Dodaj kategorię "),_c('div',{staticClass:"grey--text text-body-2"},[_vm._v(" w \""+_vm._s(_vm.parent_category ? _vm.parent_category.name : _vm.ROOT_FOLDER_NAME)+"\" ")])])]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-5",staticStyle:{"max-height":"70vh"}},[_c('v-text-field',{ref:"new_category_name",staticClass:"mb-4",attrs:{"outlined":"","label":"Nazwa","rules":[
                        v => {
                            if (!v) {
                                return 'Pole wymagane';
                            }
                            if (v.length > 24) {
                                return 'Nazwa nie może być dłuższa niż 24 znaki';
                            }
                            return true;
                        }
                    ],"hide-details":"auto"},model:{value:(_vm.new_category.name),callback:function ($$v) {_vm.$set(_vm.new_category, "name", $$v)},expression:"new_category.name"}}),_c('v-textarea',{ref:"new_category_description",attrs:{"outlined":"","label":"Opis","rules":[
                        v => {
                            if (!v) return true;
                            if (v.length > 255) {
                                return 'Opis nie może być dłuższy niż 255 znaków';
                            }
                            return true;
                        }
                    ],"hide-details":"auto","rows":"3","no-resize":""},model:{value:(_vm.new_category.description),callback:function ($$v) {_vm.$set(_vm.new_category, "description", $$v)},expression:"new_category.description"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.add_dialog = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.addCategory}},[_vm._v(" Dodaj ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","scrollable":"","persistent":""},model:{value:(_vm.edit_dialog),callback:function ($$v) {_vm.edit_dialog=$$v},expression:"edit_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edytuj kategorię")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-5",staticStyle:{"max-height":"70vh"}},[_c('v-text-field',{ref:"edit_category_name",staticClass:"mb-4",attrs:{"outlined":"","label":"Nazwa","rules":[
                        v => {
                            if (!v) {
                                return 'Pole wymagane';
                            }
                            if (v.length > 24) {
                                return 'Nazwa nie może być dłuższa niż 24 znaki';
                            }
                            return true;
                        }
                    ],"hide-details":"auto"},model:{value:(_vm.edit_category.name),callback:function ($$v) {_vm.$set(_vm.edit_category, "name", $$v)},expression:"edit_category.name"}}),_c('v-textarea',{ref:"edit_category_description",attrs:{"outlined":"","label":"Opis","rules":[
                        v => {
                            if (!v) return true;
                            if (v.length > 255) {
                                return 'Opis nie może być dłuższy niż 255 znaków';
                            }
                            return true;
                        }
                    ],"hide-details":"auto","rows":"3","no-resize":""},model:{value:(_vm.edit_category.description),callback:function ($$v) {_vm.$set(_vm.edit_category, "description", $$v)},expression:"edit_category.description"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.edit_dialog = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.editCategory}},[_vm._v(" Zapisz ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Usuń kategorię")]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-5"},[_vm._v(" Czy na pewno chcesz usunąć kategorię? "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selected_category ? _vm.selected_category.name : ""))])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.delete_loading},on:{"click":_vm.deleteCategory}},[_vm._v("Usuń")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","scrollable":""},model:{value:(_vm.move_dialog),callback:function ($$v) {_vm.move_dialog=$$v},expression:"move_dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',[_vm._v(" Przenieś kategorię "),_c('div',{staticClass:"text-body-2 grey--text"},[_vm._v(" "+_vm._s(_vm.selected_category ? _vm.selected_category.name : "n/a")+" ")])])]),_c('v-divider'),_c('v-card-text',{staticClass:"pt-5",staticStyle:{"max-height":"70vh"}},[_c('v-select',{attrs:{"outlined":"","label":"Kategoria nadrzędna elementu","hide-details":"auto","readonly":"","items":[
                        {
                            value: _vm.move_to_category_id,
                            text: '-'
                        }
                    ],"value":_vm.move_to_category_id,"clearable":_vm.move_to_category_id !== null},on:{"click:clear":() => {
                            _vm.move_to_category_id = null;
                        },"click":_vm.openMoveDialogCategorySelector,"click:append":_vm.openMoveDialogCategorySelector},scopedSlots:_vm._u([{key:"selection",fn:function(){return [(_vm.move_to_category_id === null)?[_vm._v(" "+_vm._s(_vm.ROOT_FOLDER_NAME)+" ")]:_c('DevProgramsAutoFetchEpisodeCategoryTile',{attrs:{"category-id":_vm.move_to_category_id}})]},proxy:true}])}),_c('DevProgramsEpisodeCategorySelector',{attrs:{"readonly":"","initial-category-id":_vm.move_to_category_id},on:{"category-selected":cat => {
                            _vm.move_to_category_id = cat._id;
                            _vm.move_dialog_catsel = false;
                        }},model:{value:(_vm.move_dialog_catsel),callback:function ($$v) {_vm.move_dialog_catsel=$$v},expression:"move_dialog_catsel"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.move_dialog = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.move_loading,"disabled":!_vm.move_to_category_id && _vm.move_to_category_id !== null},on:{"click":_vm.moveCategory}},[_vm._v(" Przenieś ")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }